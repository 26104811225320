import {useState, useCallback} from 'react';
import { Page, Layout, LegacyCard, Form, FormLayout, TextField, ButtonGroup, Button, Select } from '@shopify/polaris';
function AddNewBranch() {
	const [statusSelected, setStatusSelected] = useState('Đang hoạt động');
	const handleStatusChange = useCallback((value) => setStatusSelected(value), []);

	return (
		<Page title="Thêm mới cơ sở">
			<Layout>
				<Layout.AnnotatedSection
					title="Thông tin chung"
					description="Nhập thông tin cho cơ sở/phòng giao dịch"
				>
					<LegacyCard sectioned>
						<Form>
							<FormLayout>
								<TextField
									label="Tên cơ sở"
									value=""
									onChange=""
									autoComplete="off"
								/>
								<TextField
									label="Địa chỉ"
									value=""
									multiline={4}
									autoComplete="off"
								/>
								<Select
									label="Trạng thái"
									options={ [
										{label: 'Đang hoạt động', value: 'Đang hoạt động'},
										{label: 'Đã đóng', value: 'Đã đóng'},
									] }
									value={statusSelected}
									onChange={handleStatusChange}
								/>
								<ButtonGroup>
									<Button>Hủy</Button>
									<Button variant="primary">Thêm mới</Button>
								</ButtonGroup>
							</FormLayout>
						</Form>
					</LegacyCard>
				</Layout.AnnotatedSection>
			</Layout>
			</Page>
	);
}
export default AddNewBranch;