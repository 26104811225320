import {
	Page,
	IndexTable,
	LegacyCard,
	Text,
	Button,
	Thumbnail
} from '@shopify/polaris';
import React from 'react';

function AllUsers() {
	const users = [
		{
			id: '1',
			name: 'Nguyễn Văn An',
			phone: '092352352',
			avatar: 'https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light',
			role: 'Quản Lý',
			branch: 'Nguyễn Hoàng',
		},
		{
			id: '2',
			name: 'Hà Ngọc Linh',
			phone: '092352352',
			avatar: 'https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light',
			role: 'Nhân Viên',
			branch: 'Nguyễn Hoàng',
		},
		{
			id: '3',
			name: 'Hồ Ngọc Ly',
			phone: '092352352',
			avatar: 'https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light',
			role: 'Nhân Viên',
			branch: 'Nguyễn Hoàng',
		},
	];
	const resourceName = {
		singular: 'Nhân Sự',
		plural: 'Nhân Sự',
	};

	const rowMarkup = users.map(
		(
			{ id, name, phone, avatar, role, branch },
			index,
		) => (
			<IndexTable.Row
				id={id}
				key={id}
				position={index}
			>
				<IndexTable.Cell>
					<Text variant="bodyMd" fontWeight="bold" as="span">#{id}</Text>
				</IndexTable.Cell>
				<IndexTable.Cell>
					<Thumbnail source={avatar} alt={name} />
				</IndexTable.Cell>
				<IndexTable.Cell>{name}</IndexTable.Cell>
				<IndexTable.Cell>{phone}</IndexTable.Cell>
				<IndexTable.Cell>{role}</IndexTable.Cell>
				<IndexTable.Cell>{branch}</IndexTable.Cell>
				<IndexTable.Cell>
					<Text as="div" alignment="end" numeric>
						<Button variant="tertiary">Edit</Button>
						<Button variant="plain" tone="critical">Delete</Button>
					</Text>
				</IndexTable.Cell>
			</IndexTable.Row>
		),
	);

	return (
		<Page title="Tất cả nhân sự" primaryAction={{ content: 'Thêm mới', url: '/nhan-vien/them-moi' }}>
			<LegacyCard>
				<IndexTable
					selectable={false}
					resourceName={resourceName}
					itemCount={users.length}
					selectedItemsCount={0}
					headings={[
						{ title: 'STT' },
						{ title: 'Ảnh' },
						{ title: 'Họ Tên' },
						{ title: 'Số Điện Thoại' },
						{ title: 'Vai Trò' },
						{ title: 'Chi Nhánh Làm Việc' },
						{ title: 'Thao Tác', alignment: 'end' },
					]}
				>
					{rowMarkup}
				</IndexTable>
			</LegacyCard>
		</Page>
	);
}

export default AllUsers;