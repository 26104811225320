import React from "react";
import { Routes, Route } from "react-router-dom";

import Overview from "../pages/overview/Overview";
import NotFound from "../pages/notfound/NotFound";
import AllBranches from "../pages/branch/AllBranches";
import AllVehicles from "../pages/vehicle/AllVehicles";
import AddNewVehicle from "../pages/vehicle/AddNew";
import AllMaintenances from "../pages/vehicle/AllMaintenances";
import AddNewMaintenance from "../pages/vehicle/AddNewMaintenance";
import AllExpenses from "../pages/expenses/AllExpenses";
import AllBankAccounts from "../pages/expenses/AllBankAccounts";
import AddNewBankAccount from "../pages/expenses/AddNewBankAccount";
import AllContracts from "../pages/contract/AllContracts";
import AddNewContract from "../pages/contract/AddNew";
import AllUsers from "../pages/users/AllUsers";
import AddNewBranch from "../pages/branch/AddNew";
import AddNewUser from "../pages/users/AddNew";
import AddNewExpense from "../pages/expenses/AddNew";

function AppRoutes() {
	return (
		<Routes>
			<Route exact path="/" element={<Overview />}></Route>

			<Route path="/co-so" element={<AllBranches />}></Route>
			<Route path="/co-so/them-moi" element={<AddNewBranch />}></Route>

			<Route path="/phuong-tien" element={<AllVehicles />}></Route>
			<Route path="/phuong-tien/them-moi" element={<AddNewVehicle />}></Route>
			<Route path="/phuong-tien/sua-chua-bao-duong" element={<AllMaintenances />}></Route>
			<Route path="/phuong-tien/them-moi-sua-chua-bao-duong" element={<AddNewMaintenance />}></Route>

			<Route path="/hop-dong" element={<AllContracts />}></Route>
			<Route path="/hop-dong/them-moi" element={<AddNewContract />}></Route>

			<Route path="/thu-chi" element={<AllExpenses />}></Route>
			<Route path="/thu-chi/them-moi" element={<AddNewExpense />}></Route>
			<Route path="/thu-chi/tai-khoan-thanh-toan" element={<AllBankAccounts />}></Route>
			<Route path="/thu-chi/them-moi-tai-khoan-thanh-toan" element={<AddNewBankAccount />}></Route>

			<Route path="/nhan-vien" element={<AllUsers />}></Route>
			<Route path="/nhan-vien/them-moi" element={<AddNewUser />}></Route>
			

			<Route path="*" element={<NotFound />} />

			{/* <Route path="/about" element={<About />}>
				<Route path="/team" element={<Team />} />
				<Route path="/history" element={<History />} />
			</Route>
			<Route path="/users/:userId" element={<UserProfile />} /> */}
		</Routes>
	);
}

export default AppRoutes;