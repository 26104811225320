import { Page, Box, InlineGrid, BlockStack, Card, LegacyCard, Text } from '@shopify/polaris';
import { DuplicateMinor, ArchiveMinor, DeleteMinor } from '@shopify/polaris-icons';

function Overview() {
	const SkeletonLabel = (props) => {
		return (
			<Box
				background="bg-fill-tertiary"
				minHeight="1rem"
				maxWidth="5rem"
				borderRadius="base"
				{...props}
			/>
		);
	};
	return (
		<Page
			title="Tổng quan"
		>
			<InlineGrid columns={{ xs: 1, md: 3 }} gap="400">
				<BlockStack gap={{ xs: "400", md: "200" }}>
					<LegacyCard title="Khách hàng" sectioned>
						<p>50</p>
					</LegacyCard>

					<LegacyCard title="Lượt khách thuê" sectioned>
						<p>50</p>
					</LegacyCard>

					
					<LegacyCard title="Tất cả phương tiện" sectioned>
						<BlockStack gap="400">
							<SkeletonLabel />
							
						</BlockStack>
					</LegacyCard>
				</BlockStack>

				<BlockStack gap={{ xs: "400", md: "200" }}>
					<LegacyCard title="Tổng xe" sectioned>
						<p>30</p>
					</LegacyCard>

					<LegacyCard title="Tổng xe còn trống" sectioned>
						<p>15</p>
					</LegacyCard>

					<LegacyCard title="Tất cả hợp đồng" sectioned>
						<BlockStack gap="400">
							<SkeletonLabel />
							
						</BlockStack>
					</LegacyCard>
				</BlockStack>

				<BlockStack gap={{ xs: "400", md: "200" }}>
					<LegacyCard title="Tổng xe đang cho thuê" sectioned>
						<p>15</p>
					</LegacyCard>

					<LegacyCard title="Doanh thu" sectioned>
						<p>15.000.000 vnđ</p>
					</LegacyCard>

					<LegacyCard title="Tất cả thu chi" sectioned>
						<BlockStack gap="400">
							<SkeletonLabel />
							
						</BlockStack>
					</LegacyCard>
				</BlockStack>

			</InlineGrid>
		</Page>
	)
}

export default Overview;