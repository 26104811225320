import {
	Page,
	IndexTable,
	LegacyCard,
	Text,
	Button,
	Badge,
} from '@shopify/polaris';
import React from 'react';

function AllContracts() {
	const contracts = [
		{
			id: '1',
			branch: 'Nguyễn Hoàng',
			contract_id: '1001',
			customer: {
				name: 'Nguyễn Văn An',
				phone: '0927211535'
			},
			vehicle: {
				number_plate: ' 29L5-594.55'
			},
			customer_deposit_identity: 'CCCD',
			rent_type: 'Thuê trong ngày',
			customer_deposit: 2000000,
			deposit_via: 'Chuyển khoản',
			rental_start_at: '30-11-2023 11:30',
			rental_stop_at: '30-11-2023 17:30',
			rental_price: 180000,
			staff: {
				name: 'Hoa',
			},
			status: 'Đang thuê',
			extra_fee: {
				total: 120000
			}
		},
		{
			id: '2',
			branch: 'Nguyễn Hoàng',
			contract_id: '1002',
			customer: {
				name: 'Lưu Văn An',
				phone: '0927211535'
			},
			vehicle: {
				number_plate: ' 29L5-594.55'
			},
			customer_deposit_identity: 'CCCD',
			rent_type: 'Thuê trong ngày',
			customer_deposit: 2000000,
			deposit_via: 'Chuyển khoản',
			rental_start_at: '30-11-2023 11:30',
			rental_stop_at: '30-11-2023 17:30',
			rental_price: 180000,
			staff: {
				name: 'Hoa',
			},
			status: 'Đã Trả Xe',
			extra_fee: {
				total: 120000
			}
		},
	];
	const resourceName = {
		singular: 'Hợp Đồng',
		plural: 'Hợp Đồng',
	};

	const rowMarkup = contracts.map(
		(
			{ id, branch, contract_id, customer, vehicle, customer_deposit_identity, rent_type, customer_deposit, deposit_via, rental_start_at, rental_stop_at, rental_price, staff, status, extra_fee },
			index,
		) => (
			<IndexTable.Row
				id={id}
				key={id}
				position={index}
			>
				<IndexTable.Cell>
					<Text variant="bodyMd" fontWeight="bold" as="span">#{contract_id}</Text>
				</IndexTable.Cell>
				<IndexTable.Cell>
					<Badge progress="complete" tone={ 'Đang thuê' == status ? 'success' : 'warning' }>{ status }</Badge>
				</IndexTable.Cell>
				<IndexTable.Cell>{branch}</IndexTable.Cell>
				<IndexTable.Cell>{customer.name}</IndexTable.Cell>
				<IndexTable.Cell>{customer.phone}</IndexTable.Cell>
				<IndexTable.Cell>{vehicle.number_plate}</IndexTable.Cell>
				<IndexTable.Cell>{customer_deposit_identity}</IndexTable.Cell>
				<IndexTable.Cell>{customer_deposit}</IndexTable.Cell>
				<IndexTable.Cell>{deposit_via}</IndexTable.Cell>
				<IndexTable.Cell>{rental_start_at}</IndexTable.Cell>
				<IndexTable.Cell>{rental_stop_at}</IndexTable.Cell>
				<IndexTable.Cell>{rental_price}</IndexTable.Cell>
				<IndexTable.Cell>{staff.name}</IndexTable.Cell>
				
				<IndexTable.Cell>
					<Text as="div" alignment="end" numeric>
						<Button variant="tertiary">Edit</Button>
						<Button variant="plain" tone="critical">Delete</Button>
					</Text>
				</IndexTable.Cell>
			</IndexTable.Row>
		),
	);

	return (
		<Page fullWidth title="Tất cả hợp đồng" primaryAction={{ content: 'Thêm mới', url: '/hop-dong/them-moi' }}>
			<LegacyCard>
				<IndexTable
					selectable={false}
					resourceName={resourceName}
					itemCount={contracts.length}
					selectedItemsCount={0}
					headings={[
						{ title: 'ID Hợp Đồng' },
						{ title: 'Trạng Thái' },
						{ title: 'Tên Cơ Sở' },
						{ title: 'Khách Hàng' },
						{ title: 'Số Điện Thoại' },
						{ title: 'Xe' },
						{ title: 'Giấy Tờ Đặt Cọc' },
						{ title: 'Tiền Đặt Cọc' },
						{ title: 'Hình Thức Thu Cọc' },
						{ title: 'Ngày Giờ Thuê' },
						{ title: 'Ngày Giờ Trả' },
						{ title: 'Giá Thuê' },
						{ title: 'Người Giao Dịch' },
						{ title: 'Thao Tác', alignment: 'end' },
					]}
				>
					{rowMarkup}
				</IndexTable>
			</LegacyCard>
		</Page>
	);
}

export default AllContracts;