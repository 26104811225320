import { useState, useCallback } from 'react';
import { Page, InlineGrid, BlockStack, LegacyCard, ChoiceList, FormLayout, TextField, ButtonGroup, Button, DropZone, Icon, OptionList, Listbox } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';

function AddNewUser() {
	const [selected, setSelected] = useState(['nhan-vien']);
  	const handleChange = useCallback((value) => setSelected(value), []);

	const [selected2, setSelected2] = useState(['Nguyễn Hoàng']);
  	const handleChange2 = useCallback((value) => setSelected2(value), []);


	const [selectedRole, setSelectedRole] = useState([]);

	return (
		<Page title="Thêm mới nhân viên">
			<InlineGrid columns={{ xs: 1, md: "2fr 1fr" }} gap="400">
				<BlockStack gap="400">
					<LegacyCard title="Thông tin chung" sectioned>
						<BlockStack gap="400">
							<FormLayout>
								<TextField
									label="Email đăng nhập"
									type="email"
									value=""
									onChange=""
									autoComplete="off"
								/>
								<TextField
									label="Mật khẩu đăng nhập"
									type="password"
									value=""
									onChange=""
									autoComplete="off"
								/>
								<TextField
									label="Họ và tên"
									value=""
									onChange=""
									autoComplete="off"
								/>
								<TextField
									label="Số điện thoại"
									value=""
									onChange=""
									autoComplete="off"
								/>
								<ChoiceList
									title="Chức vụ"
									choices={[
										{label: 'Nhân Viên', value: 'nhan-vien'},
										{label: 'Quản Lý', value: 'quan-ly'},
										{label: 'Giám Đốc', value: 'giam-doc'},
									]}
									selected={selected}
      								onChange={handleChange}
								/>
								<ChoiceList
									title="Làm việc tại chi nhánh"
									choices={[
										{label: 'Nguyễn Hoàng', value: 'Nguyễn Hoàng'},
										{label: 'Thuốc Bắc', value: 'Thuốc Bắc'},
										{label: 'Lê Văn Hiến', value: 'Lê Văn Hiến'},
									]}
									selected={selected2}
      								onChange={handleChange2}
								/>
								
								
							</FormLayout>
						</BlockStack>
					</LegacyCard>
					
					<LegacyCard title="Phân quyền" sectioned>
						<BlockStack gap="400">
							<OptionList
								title="Chọn quyền"
								onChange={setSelectedRole}
								sections={[
									{
										title: 'Cơ sở kinh doanh',
										options: [
											{value: 'Xem1', label: 'Xem'},
											{value: 'Thêm/Sửa/Xóa1', label: 'Thêm/Sửa/Xóa'},
											{value: 'Toàn quyền1', label: 'Toàn quyền'},
										],
									},
									{
										title: 'Phương tiện',
										options: [
											{value: 'Xem2', label: 'Xem'},
											{value: 'Thêm/Sửa/Xóa2', label: 'Thêm/Sửa/Xóa'},
											{value: 'Toàn quyền2', label: 'Toàn quyền'},
										],
									},
									{
										title: 'Hợp đồng',
										options: [
											{value: 'Xem3', label: 'Xem'},
											{value: 'Thêm/Sửa/Xóa3', label: 'Thêm/Sửa/Xóa'},
											{value: 'Toàn quyền3', label: 'Toàn quyền'},
										],
									},
									{
										title: 'Thu chi',
										options: [
											{value: 'Xem4', label: 'Xem'},
											{value: 'Thêm/Sửa/Xóa4', label: 'Thêm/Sửa/Xóa'},
											{value: 'Toàn quyền4', label: 'Toàn quyền'},
										],
									},
								]}
								selected={selectedRole}
								allowMultiple
							/>
						</BlockStack>
					</LegacyCard>
				</BlockStack>
				<BlockStack gap={{ xs: "400", md: "200" }}>
					<LegacyCard title="Thông tin khác" sectioned>
						<BlockStack gap="400">
							<DropZone label="Ảnh">
								<DropZone.FileUpload />
							</DropZone>
						</BlockStack>
					</LegacyCard>
				</BlockStack>
			</InlineGrid>

			<div style={{margin: '20px 0 40px 0'}}>
				<ButtonGroup>
					<Button>Hủy</Button>
					<Button variant="primary">Thêm mới</Button>
				</ButtonGroup>
			</div>
		</Page>
	);
}
export default AddNewUser;