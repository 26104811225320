import {useState, useCallback} from 'react';
import { Page, LegacyCard, Card, BlockStack, InlineGrid, Box, ButtonGroup, Button, TextField, ChoiceList, Select, Autocomplete, Icon, DropZone } from '@shopify/polaris';
import {CirclePlusMinor, SearchMinor, DeleteMinor} from '@shopify/polaris-icons';
import SingleDatePicker from '../../components/SingleDatePicker';

function AddNewVehicle() {
	const [vehicleTypeSelected, setVehicleTypeSelected] = useState('xe-may');
  	const handleVehicleTypeChange = useCallback((value) => setVehicleTypeSelected(value), []);

	const [vehicleBrandSelected, setVehicleBrandSelected] = useState('honda');
  	const handleVehicleBrandChange = useCallback((value) => setVehicleBrandSelected(value), []);


	const [colorSelectedOptions, setColorSelectedOptions] = useState([]);
	const onColorSelected = useCallback((value) => setColorSelectedOptions(value), []);

	return (
		<Page
			title="Thêm mới phương tiện"
		>
			<InlineGrid columns={{ xs: 1, md: "2fr 1fr" }} gap="400">
				<BlockStack gap="400">
					<LegacyCard title="Thông tin chung" sectioned>
						<BlockStack gap="400">
							<TextField
								label="Biển số xe"
								value=""
								onChange=""
								autoComplete="off"
							/>
							<Select
								label="Loại xe"
								options={ [
									{label: 'Ô to', value: 'oto'},
									{label: 'Xe máy', value: 'xe-may'},
									{label: 'Xe đạp điện', value: 'xe-dap-dien'},
									{label: 'Khác', value: 'khac'},
								] }
								value={vehicleTypeSelected}
								onChange={handleVehicleTypeChange}
							/>
							{ 'khac' == vehicleTypeSelected && <TextField labelHidden placeholder="Nhập loại xe" autoComplete="off" />}

							<Select
								label="Nhãn hiệu"
								options={ [
									{label: 'Honda', value: 'honda'},
									{label: 'Yamaha', value: 'yamaha'},
									{label: 'Piago', value: 'piago'},
									{label: 'Khác', value: 'khac'},
								] }
								value={vehicleBrandSelected}
								onChange={handleVehicleBrandChange}
							/>
							{ 'khac' == vehicleBrandSelected && <TextField labelHidden placeholder="Nhập hãng sản xuất" autoComplete="off" />}

							<Select
								label="Mẫu xe"
								options={ [
									{label: 'Wave', value: 'wave'},
									{label: 'Vision', value: 'vision'},
									{label: 'Exciter', value: 'exciter'},
									{label: 'Novo', value: 'novo'},
									{label: 'Airblade', value: 'airblade'},
									{label: 'Sirius', value: 'sirius'},
								] }
								value={"wave"}
							/>

							<Autocomplete
								options={[
									{value: 'Đỏ', label: 'Đỏ'},
									{value: 'Trắng', label: 'Trắng'},
									{value: 'Xanh', label: 'Xanh'},
									{value: 'Vàng', label: 'Vàng'},
								]}
								selected={ colorSelectedOptions }
								onSelect={ onColorSelected }
								allowMultiple={true}
								loading={false}
								textField={ <Autocomplete.TextField
									label="Màu sắc"
									value=""
									prefix={<Icon source={SearchMinor} />}
									placeholder="Search"
									autoComplete="off"
								/> }
							/>

							<TextField
								label="Năm sản xuất"
								value=""
								onChange=""
								autoComplete="off"
							/>

							<TextField
								label="Số khung"
								value=""
								onChange=""
								autoComplete="off"
							/>

							<TextField
								label="Số máy"
								value=""
								onChange=""
								autoComplete="off"
							/>
						</BlockStack>
					</LegacyCard>
					<LegacyCard title="Thông tin nhập" sectioned>
						<BlockStack gap="400">
							<TextField
								label="Giá nhập"
								type="number"
								value=""
								onChange=""
								autoComplete="off"
							/>
							<SingleDatePicker title="Ngày nhập" value=""/>
						</BlockStack>
					</LegacyCard>
					<LegacyCard title="Thông tin bán" sectioned>
						<BlockStack gap="400">
							<TextField
								label="Giá bán"
								type="number"
								value=""
								onChange=""
								autoComplete="off"
							/>
							<SingleDatePicker title="Ngày bán" value=""/>
						</BlockStack>
					</LegacyCard>
				</BlockStack>
				<BlockStack gap={{ xs: "400", md: "200" }}>
					<LegacyCard title="" sectioned>
						<BlockStack gap="400">
							<Select
								label="Địa điểm để xe"
								options={ [
									{label: 'Thuốc Bắc', value: 'Thuốc Bắc'},
									{label: 'Nguyễn Hoàng', value: 'Nguyễn Hoàng'},
									{label: 'Hồ Tùng Mậu', value: 'Hồ Tùng Mậu'},
								] }
								value={"Thuốc Bắc"}
							/>

							<Select
								label="Tình trạng xe"
								options={ [
									{label: 'Tốt', value: 'Tốt'},
									{label: 'Cần sửa chữa', value: 'Cần sửa chữa'},
								] }
								value={"Thuốc Bắc"}
							/>

							<Select
								label="Trạng thái xe"
								options={ [
									{label: 'Đang cho thuê ngày', value: 'Đang cho thuê ngày'},
									{label: 'Đang cho thuê tháng', value: 'Đang cho thuê tháng'},
									{label: 'Đang sửa chữa', value: 'Đang sửa chữa'},
									{label: 'Đang bị tạm giữ', value: 'Đang bị tạm giữ'},
									{label: 'Bị chiếm đoạt', value: 'Bị chiếm đoạt'},
									{label: 'Đã bán', value: 'Đã bán'},
								] }
								value={"Đang cho thuê ngày"}
							/>
						</BlockStack>
					</LegacyCard>
					<LegacyCard title="" sectioned>
						<BlockStack gap="400">
							<DropZone label="Hình ảnh xe">
								<DropZone.FileUpload />
							</DropZone>
						</BlockStack>
					</LegacyCard>
				</BlockStack>
			</InlineGrid>

			<div style={{margin: '20px 0 40px 0'}}>
				<ButtonGroup>
					<Button>Hủy</Button>
					<Button variant="primary">Thêm mới</Button>
				</ButtonGroup>
			</div>
		</Page>
	)
}
export default AddNewVehicle;
