import { Page, Layout, LegacyCard, EmptyState } from '@shopify/polaris';
function NotFound() {
	return (
		<Page title="404 Not Found">
			
			<LegacyCard sectioned>
				<EmptyState
					heading="Page Not Available"
					action={{
						content: 'Back to Home',
						url: '/',
					}}
					image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
				>
					<p>The Page You Are Trying To Access Is Not Available Now.</p>
				</EmptyState>
			</LegacyCard>
			
		</Page>
	);
}
export default NotFound;