import {useState, useRef, useEffect} from 'react';
import { LegacyStack, FormLayout, BlockStack, Box, Icon, Card, TextField, DatePicker, Popover } from '@shopify/polaris';
import {CalendarMinor} from '@shopify/polaris-icons';
function SingleDatePicker(props) {
	function nodeContainsDescendant(rootNode, descendant) {
		if (rootNode === descendant) {
			return true;
		}
		let parent = descendant.parentNode;
		while (parent != null) {
			if (parent === rootNode) {
				return true;
			}
			parent = parent.parentNode;
		}
		return false;
	}
	const [visible, setVisible] = useState(false);
	const [selectedDate, setSelectedDate] = useState(props.value);

	const today = new Date();
	const [{ month, year }, setDate] = useState({
		month: today.getMonth(),
		year: today.getFullYear(),
	});
	const formattedValue = selectedDate != '' ? selectedDate.toISOString().slice(0, 10) : '';
	const datePickerRef = useRef(null);
	function isNodeWithinPopover(node) {
		return datePickerRef?.current
			? nodeContainsDescendant(datePickerRef.current, node)
			: false;
	}
	function handleInputValueChange() {
		console.log("handleInputValueChange");
	}
	function handleOnClose({ relatedTarget }) {
		setVisible(false);
	}
	function handleMonthChange(month, year) {
		setDate({ month, year });
	}
	function handleDateSelection({ end: newSelectedDate }) {
		setSelectedDate(newSelectedDate);
		setVisible(false);
	}
	useEffect(() => {
		if (selectedDate) {
			setDate({
				month: selectedDate.getMonth(),
				year: selectedDate.getFullYear(),
			});
		}
	}, [selectedDate]);
	return (
		<Box>
			<Popover
				active={visible}
				autofocusTarget="none"
				preferredAlignment="left"
				fullWidth
				preferInputActivator={false}
				preferredPosition="below"
				preventCloseOnChildOverlayClick
				onClose={handleOnClose}
				activator={
					<TextField
						role="combobox"
						label={ props.title }
						prefix={<Icon source={CalendarMinor} />}
						value={formattedValue}
						onFocus={() => setVisible(true)}
						onChange={handleInputValueChange}
						autoComplete="off"
					/>
				}
			>
				<Card ref={datePickerRef}>
					<DatePicker
						month={month}
						year={year}
						selected={selectedDate}
						onMonthChange={handleMonthChange}
						onChange={handleDateSelection}
					/>
				</Card>
			</Popover>
		</Box>
	)
}
export default SingleDatePicker;