import React from "react";
import '@shopify/polaris/build/esm/styles.css';
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json";
import AppFrame from "./components/AppFrame";
import LinkComponent from "./components/LinkComp";

function App() {
	return (
		<BrowserRouter>
			<AppProvider i18n={translations} linkComponent={LinkComponent}>
				<AppFrame />
			</AppProvider>
		</BrowserRouter>
	);
}

export default App;