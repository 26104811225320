import {useState, useCallback} from 'react';
import {SearchMinor, DeleteMinor} from '@shopify/polaris-icons';
import { Page, InlineGrid, BlockStack, LegacyCard, Card, FormLayout, Text, TextField, Select, ButtonGroup, Button, ChoiceList, DropZone } from '@shopify/polaris';

function AddNewBankAccount() {
	const [selected, setSelected] = useState(['Techcombank']);
  	const handleChange = useCallback((value) => setSelected(value), []);

	return (
		<Page title="Thêm mới tài khoản thanh toán">
			<InlineGrid columns={{ xs: 1, md: "2fr 1fr" }} gap="400">
				<BlockStack gap="400">
					<LegacyCard title="Thông tin chung" sectioned>
						<BlockStack gap="400">
							<FormLayout>
								<ChoiceList
									title="Ngân hàng"
									choices={[
										{label: 'Techcombank', value: 'Techcombank'},
										{label: 'MB Bank', value: 'MB Bank'},
										{label: 'ACB', value: 'ACB'},
										{label: 'VCB', value: 'VCB'},
									]}
									selected={selected}
									onChange={handleChange}
								/>

								<TextField
									label="Người thụ hưởng"
									value=""
									onChange=""
									autoComplete="off"
								/>

								<TextField
									label="Số tài khoản"
									value=""
									onChange=""
									autoComplete="off"
								/>
								<TextField
									label="Ghi chú"
									value=""
									multiline={4}
									autoComplete="off"
								/>
							</FormLayout>
						</BlockStack>
					</LegacyCard>
				</BlockStack>
				<BlockStack gap={{ xs: "400", md: "200" }}>
					<LegacyCard title="Mã QR" sectioned>
						<BlockStack gap="400">
							<DropZone label="Hình ảnh mã QR code">
								<DropZone.FileUpload />
							</DropZone>
						</BlockStack>
					</LegacyCard>
				</BlockStack>
			</InlineGrid>

			<div style={{margin: '20px 0 40px 0'}}>
				<ButtonGroup>
					<Button>Hủy</Button>
					<Button variant="primary">Thêm mới</Button>
				</ButtonGroup>
			</div>
		</Page>
	);
}
export default AddNewBankAccount;