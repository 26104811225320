import {
	Page,
	IndexTable,
	LegacyCard,
	Text,
	Button,
	Badge
} from '@shopify/polaris';
import React from 'react';

function AllMaintenances() {
	const branches = [
		{
			id: '1',
			number_plate: '20A-92745',
			started_at: '12-12-2023 15:30',
			done_at: '20-12-2023 18:00',
			status: 'Đã xử lý',
			note: '',
		},
		{
			id: '2',
			number_plate: '20A-92745',
			started_at: '12-12-2023 15:30',
			done_at: '20-12-2023 18:00',
			status: 'Đang xử lý',
			note: 'Xe không thể đề nổ',
		},
	];
	const resourceName = {
		singular: 'Cơ Sở',
		plural: 'Cơ Sở',
	};

	const rowMarkup = branches.map(
		(
			{ id, number_plate, started_at, done_at, status, note },
			index,
		) => (
			<IndexTable.Row
				id={id}
				key={id}
				position={index}
			>
				<IndexTable.Cell>
					<Text variant="bodyMd" fontWeight="bold" as="span">#{id}</Text>
				</IndexTable.Cell>
				<IndexTable.Cell>{number_plate}</IndexTable.Cell>
				<IndexTable.Cell>{started_at}</IndexTable.Cell>
				<IndexTable.Cell>{done_at}</IndexTable.Cell>
				<IndexTable.Cell>
					<Badge progress="complete" tone={ 'Đã xử lý' == status ? 'success' : 'warning' }>{ status }</Badge>
				</IndexTable.Cell>
				<IndexTable.Cell>{note}</IndexTable.Cell>
				<IndexTable.Cell>
					<Text as="div" alignment="end" numeric>
						<Button variant="tertiary">Edit</Button>
						<Button variant="plain" tone="critical">Delete</Button>
					</Text>
				</IndexTable.Cell>
			</IndexTable.Row>
		),
	);

	return (
		<Page title="Tất cả yêu cầu sửa chữa/bảo dưỡng" primaryAction={{ content: 'Thêm mới', url: '/phuong-tien/them-moi-sua-chua-bao-duong' }}>
			<LegacyCard>
				<IndexTable
					selectable={false}
					resourceName={resourceName}
					itemCount={branches.length}
					selectedItemsCount={0}
					headings={[
						{ title: 'STT' },
						{ title: 'Phương tiện' },
						{ title: 'Ngày bắt đầu' },
						{ title: 'Ngày kết thúc' },
						{ title: 'Trạng thái' },
						{ title: 'Ghi chú' },
						{ title: 'Thao Tác', alignment: 'end' },
					]}
				>
					{rowMarkup}
				</IndexTable>
			</LegacyCard>
		</Page>
	);
}

export default AllMaintenances;