import {
	Page,
	IndexTable,
	LegacyCard,
	Text,
	Button,
} from '@shopify/polaris';
import React from 'react';

function AllBankAccounts() {
	const branches = [
		{
			id: '1',
			bank_name: 'MB Bank',
			holder_name: 'Nguyễn Văn An',
			bank_account_number: '0299992323525',
		},
		{
			id: '2',
			bank_name: 'Techcombank',
			holder_name: 'Hoàng Thị Nhung',
			bank_account_number: '0299992323525',
		},
	];
	const resourceName = {
		singular: 'Cơ Sở',
		plural: 'Cơ Sở',
	};

	const rowMarkup = branches.map(
		(
			{ id, bank_name, holder_name, bank_account_number },
			index,
		) => (
			<IndexTable.Row
				id={id}
				key={id}
				position={index}
			>
				<IndexTable.Cell>
					<Text variant="bodyMd" fontWeight="bold" as="span">#{id}</Text>
				</IndexTable.Cell>
				<IndexTable.Cell>{bank_name}</IndexTable.Cell>
				<IndexTable.Cell>{holder_name}</IndexTable.Cell>
				<IndexTable.Cell>{bank_account_number}</IndexTable.Cell>
				<IndexTable.Cell>
					<Text as="div" alignment="end" numeric>
						<Button variant="tertiary">Edit</Button>
						<Button variant="plain" tone="critical">Delete</Button>
					</Text>
				</IndexTable.Cell>
			</IndexTable.Row>
		),
	);

	return (
		<Page title="Tất cả tài khoản" primaryAction={{ content: 'Thêm mới', url: '/thu-chi/them-moi-tai-khoan-thanh-toan' }}>
			<LegacyCard>
				<IndexTable
					selectable={false}
					resourceName={resourceName}
					itemCount={branches.length}
					selectedItemsCount={0}
					headings={[
						{ title: 'STT' },
						{ title: 'Tên ngân hàng' },
						{ title: 'Người thụ hưởng' },
						{ title: 'Số tài khoản' },
						{ title: 'Thao tác', alignment: 'end' },
					]}
				>
					{rowMarkup}
				</IndexTable>
			</LegacyCard>
		</Page>
	);
}

export default AllBankAccounts;