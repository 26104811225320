import {
	Page,
	IndexTable,
	LegacyCard,
	Text,
	Button,
	Badge
} from '@shopify/polaris';
import React from 'react';

function AllBranches() {
	const branches = [
		{
			id: '1',
			name: 'Nguyễn Hoàng',
			status: 'Đang hoạt động',
			address: '123 Nguyễn Hoàng',
		},
		{
			id: '2',
			name: 'Thuốc Bắc',
			status: 'Đã đóng',
			address: '123 Thuốc Bắc',
		},
		{
			id: '3',
			name: 'Lê Văn Hiến',
			status: 'Đang hoạt động',
			address: 'Số 1, Lê Văn Hiến',
		},
		{
			id: '4',
			name: 'Đức Diễn',
			status: 'Đang hoạt động',
			address: 'Số 1, Đức Diễn',
		},
		{
			id: '5',
			name: 'Cầu Diễn',
			status: 'Đang hoạt động',
			address: 'Số 10, Cầu Diễn',
		},
		{
			id: '6',
			name: 'Trần Đại Nghĩa',
			status: 'Đang hoạt động',
			address: 'Số 2, Trần Đại Nghĩa',
		},
		{
			id: '7',
			status: 'Đang hoạt động',
			name: 'Telesale',
			address: '',
		},
	];
	const resourceName = {
		singular: 'Cơ Sở',
		plural: 'Cơ Sở',
	};

	const rowMarkup = branches.map(
		(
			{ id, name, address, status },
			index,
		) => (
			<IndexTable.Row
				id={id}
				key={id}
				position={index}
			>
				<IndexTable.Cell>
					<Text variant="bodyMd" fontWeight="bold" as="span">#{id}</Text>
				</IndexTable.Cell>
				<IndexTable.Cell>{name}</IndexTable.Cell>
				<IndexTable.Cell>{address}</IndexTable.Cell>
				<IndexTable.Cell><Badge progress="complete" tone={ 'Đang hoạt động' == status ? 'success' : 'warning' }>{ status }</Badge></IndexTable.Cell>
				<IndexTable.Cell>
					<Text as="div" alignment="end" numeric>
						<Button variant="tertiary">Edit</Button>
						<Button variant="plain" tone="critical">Delete</Button>
					</Text>
				</IndexTable.Cell>
			</IndexTable.Row>
		),
	);

	return (
		<Page title="Tất cả cơ sở" primaryAction={{ content: 'Thêm mới', url: '/co-so/them-moi' }}>
			<LegacyCard>
				<IndexTable
					selectable={false}
					resourceName={resourceName}
					itemCount={branches.length}
					selectedItemsCount={0}
					headings={[
						{ title: 'STT' },
						{ title: 'Tên cơ sở' },
						{ title: 'Địa chỉ' },
						{ title: 'Trạng thái' },
						{ title: 'Thao tác', alignment: 'end' },
					]}
				>
					{rowMarkup}
				</IndexTable>
			</LegacyCard>
		</Page>
	);
}

export default AllBranches;