import {useState, useCallback} from 'react';
import { Page, LegacyCard, Card, BlockStack, InlineGrid, Box, ButtonGroup, Button, TextField, ChoiceList, Select, Autocomplete, Icon, DropZone } from '@shopify/polaris';
import {CirclePlusMinor, SearchMinor, DeleteMinor} from '@shopify/polaris-icons';
import SingleDatePicker from '../../components/SingleDatePicker';

function AddNewMaintenance() {
	return (
		<Page
			title="Thêm mới yêu cầu sửa chữa/bảo dưỡng"
		>
			<InlineGrid columns={{ xs: 1, md: "2fr 1fr" }} gap="400">
				<BlockStack gap="400">
					<LegacyCard title="Thông tin chung" sectioned>
						<BlockStack gap="400">
							<Select
								label="Phương tiện"
								options={ [
									{label: '20A-13515', value: '20A-13515'},
									{label: '30B-13851', value: '30B-13851'},
								] }
								value={"20A-13515"}
							/>
							<SingleDatePicker title="Ngày bắt đầu" value={ new Date() }/>
							<SingleDatePicker title="Ngày kết thúc(dự kiến)" value=""/>
							<TextField
								label="Ghi chú"
								value=""
								multiline={4}
								autoComplete="off"
							/>
						</BlockStack>
					</LegacyCard>
				</BlockStack>
				<BlockStack gap={{ xs: "400", md: "200" }}>
					<Card roundedAbove="sm">
						<BlockStack gap="400">
							<Select
								label="Trạng thái"
								options={ [
									{label: 'Đang xử lý', value: 'Đang xử lý'},
									{label: 'Đã xử lý', value: 'Đã xử lý'},
								] }
								value={"Đang xử lý"}
							/>

						</BlockStack>
					</Card>
					
				</BlockStack>
			</InlineGrid>

			<div style={{margin: '20px 0 40px 0'}}>
				<ButtonGroup>
					<Button>Hủy</Button>
					<Button variant="primary">Thêm mới</Button>
				</ButtonGroup>
			</div>
		</Page>
	)
}
export default AddNewMaintenance;
