import {
	Page,
	IndexTable,
	LegacyCard,
	Text,
	Button,
	Badge,
	Thumbnail
} from '@shopify/polaris';
import React from 'react';

function AllVehicles() {
	const vehicles = [
		{
			id: 9,
			number_plate: "18G1-462.25",
			firm: "HONDA",
			year_of_manufacture: 2021,
			name: "WAVE",
			color: "TRẮNG -ĐEN - BẠC",
			vehicle_identification_number: "RLHJA3922LY074307",
			vehicle_machine_number: "JA39E2078357",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ NGÀY",
			base_cost: "14,700,000",
			bought_date: "27/07/22",
			fixing_fee: "70,000",
			final_price: "14,770,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 18,
			number_plate: "12T1-217.42",
			firm: "HONDA",
			year_of_manufacture: 2021,
			name: "vison",
			color: "ĐỎ NÂU ĐEN",
			vehicle_identification_number: "RLHJK031XMZ360901",
			vehicle_machine_number: "JK03E6073156",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ THÁNG",
			base_cost: "26,500,000",
			bought_date: "17/02/23",
			fixing_fee: "",
			final_price: "26,500,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 29,
			number_plate: "99H1-385.06",
			firm: "HONDA",
			year_of_manufacture: 2022,
			name: "SH MODE",
			color: "XANH",
			vehicle_identification_number: "RLHJK015NZ106198",
			vehicle_machine_number: "JK01E0304338",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "51,000,000",
			bought_date: "13/12/22",
			fixing_fee: "",
			final_price: "51,000,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 37,
			number_plate: "29S1-236.61",
			firm: "HONDA",
			year_of_manufacture: 2013,
			name: "AIRBLADE 125",
			color: "ĐỎ -TRẮNG - ĐEN",
			vehicle_identification_number: "515804",
			vehicle_machine_number: "4029678",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ NGÀY",
			base_cost: "21,700,000",
			bought_date: "21/07/22",
			fixing_fee: "285,000",
			final_price: "21,985,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 38,
			number_plate: "36B6-953.84",
			firm: "HONDA",
			year_of_manufacture: 2019,
			name: "WAVE 110",
			color: "ĐỎ ĐEN BẠC",
			vehicle_identification_number: "3911KY039215",
			vehicle_machine_number: "JA39E0895231",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ THÁNG",
			base_cost: "13,000,000",
			bought_date: "22/02/23",
			fixing_fee: "",
			final_price: "13,000,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 46,
			number_plate: "17B8-106.71",
			firm: "HONDA",
			year_of_manufacture: 2015,
			name: "WAVE 100",
			color: "ĐEN",
			vehicle_identification_number: "1216DY524265",
			vehicle_machine_number: "HC12E5522113",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ THÁNG",
			base_cost: "10,800,000",
			bought_date: "Ban đầu",
			fixing_fee: "",
			final_price: "10,800,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 47,
			number_plate: "29V5-024.29",
			firm: "HONDA",
			year_of_manufacture: 2011,
			name: "AIRBLADE",
			color: "ĐEN -BẠC",
			vehicle_identification_number: "034147",
			vehicle_machine_number: "JF27E0740306",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "13,200,000",
			bought_date: "30/07/22",
			fixing_fee: "",
			final_price: "13,200,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 48,
			number_plate: "17B8-207.40",
			firm: "HONDA",
			year_of_manufacture: 2016,
			name: "LEAD",
			color: "ĐEN",
			vehicle_identification_number: "4514FY142924",
			vehicle_machine_number: "JF45E0855477",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ THÁNG",
			base_cost: "23,500,000",
			bought_date: "Ban đầu",
			fixing_fee: "",
			final_price: "23,500,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 52,
			number_plate: "21B1-909.74",
			firm: "YAMAHA",
			year_of_manufacture: 2019,
			name: "SIRIUS 110",
			color: "XÁM - ĐEN",
			vehicle_identification_number: "3240JY218670",
			vehicle_machine_number: "E3X9E587121",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ THÁNG",
			base_cost: "12,200,000",
			bought_date: "Ban đầu",
			fixing_fee: "",
			final_price: "12,200,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 57,
			number_plate: "29Y7-040.62",
			firm: "HONDA",
			year_of_manufacture: 2011,
			name: "AIR BLADE",
			color: "TRẮNG- ĐỎ-ĐEN",
			vehicle_identification_number: "XBY779691",
			vehicle_machine_number: "E-0871317",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "12,200,000",
			bought_date: "22/07/22",
			fixing_fee: "",
			final_price: "12,200,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 59,
			number_plate: "29Z1-076.67",
			firm: "PIAGIO",
			year_of_manufacture: 2017,
			name: "LIBERTY",
			color: "TRẮNG ",
			vehicle_identification_number: "3100BV027424",
			vehicle_machine_number: "M731M4027460",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "11,500,000",
			bought_date: "Ban đầu",
			fixing_fee: "",
			final_price: "11,500,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 64,
			number_plate: "29F1-248.59",
			firm: "HONDA",
			year_of_manufacture: 2014,
			name: "WAVE 110",
			color: "VÀNG -ĐEN",
			vehicle_identification_number: "24872",
			vehicle_machine_number: "136110",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ THÁNG",
			base_cost: "10,355,000",
			bought_date: "Ban đầu",
			fixing_fee: "",
			final_price: "10,355,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 80,
			number_plate: "37L2-255.91",
			firm: "HONDA",
			year_of_manufacture: 2019,
			name: "VISION 110",
			color: "VÀNG -NÂU -ĐEN",
			vehicle_identification_number: "5837KY445578",
			vehicle_machine_number: "JF86E0138311",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ NGÀY",
			base_cost: "23,000,000",
			bought_date: "23/07/22",
			fixing_fee: "145,000",
			final_price: "23,145,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 84,
			number_plate: "28F1-096.15",
			firm: "HONDA",
			year_of_manufacture: 2016,
			name: "VISION",
			color: "Trắng",
			vehicle_identification_number: "5817GY032856",
			vehicle_machine_number: "JF66E0032380",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ NGÀY",
			base_cost: "17,500,000",
			bought_date: "30/05/23",
			fixing_fee: "",
			final_price: "17,500,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 85,
			number_plate: "12P1-202.55",
			firm: "HONDA",
			year_of_manufacture: 2018,
			name: "VISION 110",
			color: "ĐEN ĐỎ",
			vehicle_identification_number: "5810GY776377",
			vehicle_machine_number: "JF66E0833932",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ THÁNG",
			base_cost: "21,300,000",
			bought_date: "28/01/23",
			fixing_fee: "",
			final_price: "21,300,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 87,
			number_plate: "28H1-433.43",
			firm: "HONDA",
			year_of_manufacture: 2018,
			name: "Vision",
			color: "ĐỎ NÂU ĐEN",
			vehicle_identification_number: "58822JY035051",
			vehicle_machine_number: "JF86E0115609",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ THÁNG",
			base_cost: "21,000,000",
			bought_date: "13/12/22",
			fixing_fee: "",
			final_price: "21,000,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 92,
			number_plate: "29F1-304.67",
			firm: "HONDA",
			year_of_manufacture: 2015,
			name: "VISION",
			color: "ĐỎ",
			vehicle_identification_number: "5801EY204030",
			vehicle_machine_number: "JF58E0204046",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ NGÀY",
			base_cost: "23,000,000",
			bought_date: "04/08/22",
			fixing_fee: "",
			final_price: "23,000,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 101,
			number_plate: "29k1-487.03",
			firm: "HONDA",
			year_of_manufacture: 2013,
			name: "WAVE",
			color: "Trắng- Đen - Bạc",
			vehicle_identification_number: "RLHHC121XDY710049",
			vehicle_machine_number: "HC12E5709515",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ THÁNG",
			base_cost: "11,000,000",
			bought_date: "29/10/22",
			fixing_fee: "",
			final_price: "11,000,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 104,
			number_plate: "29L1-274.37",
			firm: "HONDA",
			year_of_manufacture: 2010,
			name: "Airblade",
			color: "ĐEN",
			vehicle_identification_number: "1808AY484838",
			vehicle_machine_number: "JF27E0502812",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ NGÀY",
			base_cost: "15,200,000",
			bought_date: "24/10/22",
			fixing_fee: "",
			final_price: "15,200,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 105,
			number_plate: "29L1-406.37",
			firm: "HONDA",
			year_of_manufacture: 2013,
			name: "AIRBLADE 125",
			color: "VÀNG",
			vehicle_identification_number: "400923",
			vehicle_machine_number: "73221",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ THÁNG",
			base_cost: "22,500,000",
			bought_date: "Ban đầu",
			fixing_fee: "",
			final_price: "22,500,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 107,
			number_plate: "88D1-335.22",
			firm: "HONDA",
			year_of_manufacture: 2017,
			name: "AIR BLADE",
			color: "ĐỎ - BẠC - ĐEN",
			vehicle_identification_number: "6300FZ212399",
			vehicle_machine_number: "JF63E1566485",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ NGÀY",
			base_cost: "26,000,000",
			bought_date: "08/08/22",
			fixing_fee: "",
			final_price: "26,000,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 110,
			number_plate: "29S6-109.02",
			firm: "HONDA",
			year_of_manufacture: 2011,
			name: "AIRBLADE",
			color: "ĐỎ -ĐEN-TRẮNG",
			vehicle_identification_number: "266649",
			vehicle_machine_number: "1063215",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "12,800,000",
			bought_date: "21/08/22",
			fixing_fee: "660,000",
			final_price: "13,460,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 115,
			number_plate: "98H1-170.49",
			firm: "HONDA",
			year_of_manufacture: 2015,
			name: "LEAD",
			color: "ĐEN TRẮNG",
			vehicle_identification_number: "4517FY794999",
			vehicle_machine_number: "JF45E0832680",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ THÁNG",
			base_cost: "19,000,000",
			bought_date: "08/02/23",
			fixing_fee: "",
			final_price: "19,000,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 122,
			number_plate: " 29L1-831.28 ",
			firm: "HONDA",
			year_of_manufacture: 2021,
			name: "WARE 110",
			color: "Xanh đen bạc",
			vehicle_identification_number: "RLHJA3926MY099115",
			vehicle_machine_number: "JA39E2216441",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ THÁNG",
			base_cost: "16,300,000",
			bought_date: "10/09/22",
			fixing_fee: "",
			final_price: "16,300,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 125,
			number_plate: "28G1-221.84",
			firm: "HONDA",
			year_of_manufacture: 2018,
			name: "vison",
			color: "đỏ nâu",
			vehicle_identification_number: "5812Gy835445",
			vehicle_machine_number: "JF66B0575675",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ NGÀY",
			base_cost: "",
			bought_date: "29/09/22",
			fixing_fee: "",
			final_price: "18,500,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 129,
			number_plate: "34b2-917.12",
			firm: "HONDA",
			year_of_manufacture: 2015,
			name: "WAVE 100",
			color: "XÁM ĐEN BẠC",
			vehicle_identification_number: "1219DY743933",
			vehicle_machine_number: "HC12E5744115",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ NGÀY",
			base_cost: "11,300,000",
			bought_date: "15/10/22",
			fixing_fee: "245,000",
			final_price: "11,545,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 131,
			number_plate: "29L5-595.97",
			firm: "VINFAST",
			year_of_manufacture: 2023,
			name: "EVO 200",
			color: "TRẮNG",
			vehicle_identification_number: "RPXP1LHHVPE006249",
			vehicle_machine_number: "VFHCEAD230215D0094",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "",
			bought_date: "",
			fixing_fee: "",
			final_price: "0",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 133,
			number_plate: " 90B3-092.33 ",
			firm: "HONDA",
			year_of_manufacture: 2019,
			name: "WAVE 110",
			color: "TRĂNG ĐEN BẠC",
			vehicle_identification_number: "3917KY429008",
			vehicle_machine_number: "JA39E1299315",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "22,500,000",
			bought_date: "22/10/22",
			fixing_fee: "",
			final_price: "22,500,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 134,
			number_plate: "29P1-068.13",
			firm: "HONDA",
			year_of_manufacture: 2011,
			name: "Airblade",
			color: "ĐEN-TRẮNG-ĐỎ",
			vehicle_identification_number: "800819",
			vehicle_machine_number: "904563",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "11,000,000",
			bought_date: "18/03/23",
			fixing_fee: "",
			final_price: "11,000,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 144,
			number_plate: "36F1-311.61",
			firm: "HONDA",
			year_of_manufacture: null,
			name: "WAVE",
			color: "",
			vehicle_identification_number: "",
			vehicle_machine_number: "",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "12,800,000",
			bought_date: "24/10/22",
			fixing_fee: "65,000",
			final_price: "12,865,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 147,
			number_plate: "28G1-097.14",
			firm: "PIAGIO",
			year_of_manufacture: 2014,
			name: "Liberty",
			color: "Xanh",
			vehicle_identification_number: "3401EV101723",
			vehicle_machine_number: "M739M5002952",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ THÁNG",
			base_cost: "11,000,000",
			bought_date: "25/10/22",
			fixing_fee: "",
			final_price: "11,000,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 155,
			number_plate: "29B1-360.83",
			firm: "HONDA",
			year_of_manufacture: 2013,
			name: "airblade",
			color: "Trắng-đỏ-đen",
			vehicle_identification_number: "270780",
			vehicle_machine_number: "4129530",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "18,500,000",
			bought_date: "19/11/22",
			fixing_fee: "",
			final_price: "18,500,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 156,
			number_plate: "29X1-081.63",
			firm: "HONDA",
			year_of_manufacture: 2011,
			name: "Airblade",
			color: "TRẮNG",
			vehicle_identification_number: "148597",
			vehicle_machine_number: "968234",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ NGÀY",
			base_cost: "13,500,000",
			bought_date: "22/10/22",
			fixing_fee: "",
			final_price: "13,500,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 163,
			number_plate: "88L1-643.49",
			firm: "HONDA",
			year_of_manufacture: 2021,
			name: "Airblade",
			color: "ĐEN",
			vehicle_identification_number: "RLHJF6337MZ603248",
			vehicle_machine_number: "JF94E0230053",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ NGÀY",
			base_cost: "30,000,000",
			bought_date: "13/12/22",
			fixing_fee: "",
			final_price: "30,000,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 166,
			number_plate: "29Z1-260.05",
			firm: "HONDA",
			year_of_manufacture: 2014,
			name: "AIR BLADE ",
			color: "TRẮNG -XÁM",
			vehicle_identification_number: "112413",
			vehicle_machine_number: "134927",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ NGÀY",
			base_cost: "17,500,000",
			bought_date: "01/08/22",
			fixing_fee: "230,000",
			final_price: "17,730,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 167,
			number_plate: "29Z1-454.44",
			firm: "YAMAHA",
			year_of_manufacture: 2017,
			name: "EXCITER 150",
			color: "XANH -BẠC -ĐEN",
			vehicle_identification_number: "0610HY433239",
			vehicle_machine_number: "G3D4E453745",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "25,040,000",
			bought_date: "Ban đầu",
			fixing_fee: "",
			final_price: "25,040,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 169,
			number_plate: "36E1-338.60",
			firm: "HONDA",
			year_of_manufacture: 2018,
			name: "VISION",
			color: "TRẮNG NÂU ĐEN",
			vehicle_identification_number: "582XJY004856",
			vehicle_machine_number: "JF86E0009468",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ THÁNG",
			base_cost: "20,000,000",
			bought_date: "17/01/23",
			fixing_fee: "",
			final_price: "20,000,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 179,
			number_plate: "35B1-136.78",
			firm: "HONDA",
			year_of_manufacture: 2011,
			name: "VISON ",
			color: "ĐEN",
			vehicle_identification_number: "301B1001787",
			vehicle_machine_number: "2 11DO1663",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "11,865,000",
			bought_date: "Ban đầu",
			fixing_fee: "",
			final_price: "11,865,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 183,
			number_plate: "29V7-166.08",
			firm: "HONDA",
			year_of_manufacture: 2011,
			name: "AB",
			color: "VÀNG-BẠC-ĐEN",
			vehicle_identification_number: "714160",
			vehicle_machine_number: "535376",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "14,600,000",
			bought_date: "18/03/23",
			fixing_fee: "",
			final_price: "14,600,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 198,
			number_plate: "29L5-595.67",
			firm: "VINFAST",
			year_of_manufacture: 2023,
			name: "EVO 200",
			color: "ĐEN",
			vehicle_identification_number: "RPXP1LHHVPE007792",
			vehicle_machine_number: "VFHCEAD230215D0016",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "",
			bought_date: "",
			fixing_fee: "",
			final_price: "0",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 199,
			number_plate: "29L5-595.71",
			firm: "VINFAST",
			year_of_manufacture: 2023,
			name: "EVO 200",
			color: "ĐEN",
			vehicle_identification_number: "RPXP1LHHVPE007793",
			vehicle_machine_number: "VFHCEAD230215D0007",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ THÁNG",
			base_cost: "",
			bought_date: ".13",
			fixing_fee: "",
			final_price: "0",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 200,
			number_plate: "88L1-549.48",
			firm: "PIAGIO",
			year_of_manufacture: 2019,
			name: "VESPA LX",
			color: "TRẮNG",
			vehicle_identification_number: "2100FV041172",
			vehicle_machine_number: "M821M501577",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "22,000,000",
			bought_date: "08/08/22",
			fixing_fee: "1,120,000",
			final_price: "23,120,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 201,
			number_plate: "29L5-594.00",
			firm: "VINFAST",
			year_of_manufacture: 2023,
			name: "EVO 200",
			color: "ĐEN",
			vehicle_identification_number: "RPXP1LHHVPE007799",
			vehicle_machine_number: "VFHCEAD230215D0160",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ NGÀY",
			base_cost: "",
			bought_date: "",
			fixing_fee: "",
			final_price: "0",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 202,
			number_plate: "29L5-594.13",
			firm: "VINFAST",
			year_of_manufacture: 2023,
			name: "EVO 200",
			color: "ĐEN",
			vehicle_identification_number: "RPXP1LHHVPE007808",
			vehicle_machine_number: "VFHCEAD230215D0014",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "",
			bought_date: "",
			fixing_fee: "",
			final_price: "0",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 208,
			number_plate: "29L5-594.89",
			firm: "VINFAST",
			year_of_manufacture: 2023,
			name: "EVO 200",
			color: "TRẮNG",
			vehicle_identification_number: "RPXP1LHHVPE006247",
			vehicle_machine_number: "VFHCEAD230215D0101",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "",
			bought_date: "",
			fixing_fee: "",
			final_price: "0",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 209,
			number_plate: "29L5-594.97",
			firm: "VINFAST",
			year_of_manufacture: 2023,
			name: "EVO 200",
			color: "TRẮNG",
			vehicle_identification_number: "RPXP1LHHVPE006313",
			vehicle_machine_number: "VFHCEAD230215D0131",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "",
			bought_date: "",
			fixing_fee: "",
			final_price: "0",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 211,
			number_plate: "98b2-918.76",
			firm: "HONDA",
			year_of_manufacture: 2017,
			name: "WAVE ",
			color: "TRẮNG -ĐEN -BẠC",
			vehicle_identification_number: "1259FY406071",
			vehicle_machine_number: "HC12E7406022",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "12,800,000",
			bought_date: "Ban đầu",
			fixing_fee: "",
			final_price: "12,800,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 216,
			number_plate: "29Z1-572.67",
			firm: "HONDA",
			year_of_manufacture: 2019,
			name: "WAVE",
			color: "Trắng Đen Bạc",
			vehicle_identification_number: "3916KY011717",
			vehicle_machine_number: "JA39E0867719",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ NGÀY",
			base_cost: "13,000,000",
			bought_date: "23/05/23",
			fixing_fee: "",
			final_price: "13,000,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 219,
			number_plate: "98E1-327.74",
			firm: "HONDA",
			year_of_manufacture: 2015,
			name: "SH125I",
			color: "Đen",
			vehicle_identification_number: "4513DY097699",
			vehicle_machine_number: "JF42E0097702",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "41,000,000",
			bought_date: "30/05/23",
			fixing_fee: "",
			final_price: "41,000,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 223,
			number_plate: "29X1-676.60",
			firm: "HONDA",
			year_of_manufacture: 2018,
			name: "WAVE",
			color: "Trắng Đen Bạc",
			vehicle_identification_number: "3900HY478339",
			vehicle_machine_number: "JA39E0453467",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ NGÀY",
			base_cost: "11,300,000",
			bought_date: "01/06/23",
			fixing_fee: "",
			final_price: "11,300,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 229,
			number_plate: "19C1-365.86",
			firm: "HONDA",
			year_of_manufacture: 2020,
			name: "AIR BLADE",
			color: "Bạc Xanh Đen",
			vehicle_identification_number: "6338LZ031704",
			vehicle_machine_number: "JF94E0076957",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ THÁNG",
			base_cost: "29,100,000",
			bought_date: "16/06/23",
			fixing_fee: "",
			final_price: "29,100,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 233,
			number_plate: "26D1-406.98",
			firm: "HONDA",
			year_of_manufacture: 2023,
			name: "WAVE",
			color: "Đen",
			vehicle_identification_number: "RLHJA3920NY747128",
			vehicle_machine_number: "JA39E2967503",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "THUÊ NGÀY",
			base_cost: "12,800,000",
			bought_date: "13/07/23",
			fixing_fee: "",
			final_price: "12,800,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 246,
			number_plate: "28G1-101.13",
			firm: "HONDA",
			year_of_manufacture: 2017,
			name: "VISION",
			color: "Trắng",
			vehicle_identification_number: "580XEY037859",
			vehicle_machine_number: "JF58E0037955",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "15,800,000",
			bought_date: "23/07/23",
			fixing_fee: "",
			final_price: "15,800,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 257,
			number_plate: "89K1-064.97",
			firm: "HONDA",
			year_of_manufacture: 2012,
			name: "VISION",
			color: "Nâu Bạc Đen",
			vehicle_identification_number: "3309CY107294",
			vehicle_machine_number: "F33E-0107326",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "10,800,000",
			bought_date: "28/07/23",
			fixing_fee: "",
			final_price: "10,800,000",
			selling_price: "",
			selling_date: ""
		},
		{
			id: 266,
			number_plate: "98B3-078.11",
			firm: "HONDA",
			year_of_manufacture: 2017,
			name: "WAVE",
			color: "Trắng Đen Bạc",
			vehicle_identification_number: "390XHY284935",
			vehicle_machine_number: "JA39E0284964",
			branch: "THUỐC BẮC",
			vehicle_status: "HOẠT ĐỘNG",
			status: "LƯU KHO",
			base_cost: "9,300,000",
			bought_date: "30/07/23",
			fixing_fee: "",
			final_price: "9,300,000",
			selling_price: "",
			selling_date: ""
		}
	];
	const resourceName = {
		singular: 'Cơ Sở',
		plural: 'Cơ Sở',
	};

	function statusBadge(status) {
		let badge;
		if ( 'THUÊ NGÀY' == status ) {
			badge = <Badge tone="success">{status}</Badge>
		} else if ( 'LƯU KHO' == status ) {
			badge = <Badge tone="warning">{status}</Badge>
		} else {
			badge = <Badge tone="info">{status}</Badge>
		}
		return badge;
	}

	const rowMarkup = vehicles.map(
		(
			{ id, number_plate, firm, year_of_manufacture, name, color, vehicle_machine_number, vehicle_identification_number, branch, vehicle_status, status, base_cost, bought_date, fixing_fee, final_price },
			index,
		) => (
			<IndexTable.Row
				id={id}
				key={id}
				position={index}
			>
				<IndexTable.Cell>
					<Text variant="bodyMd" fontWeight="bold" as="span">#{id}</Text>
				</IndexTable.Cell>
				<IndexTable.Cell>
					<Thumbnail
						source="https://cdn.honda.com.vn/motorbikes/May2022/yXVDCgQDZJcYqcCZPzyQ.png"
						size="large"
						alt={number_plate}
					/>
				</IndexTable.Cell>
				<IndexTable.Cell>{number_plate}</IndexTable.Cell>
				<IndexTable.Cell>{firm}</IndexTable.Cell>
				<IndexTable.Cell>{year_of_manufacture}</IndexTable.Cell>
				<IndexTable.Cell>{name}</IndexTable.Cell>
				<IndexTable.Cell>{color}</IndexTable.Cell>
				<IndexTable.Cell>{vehicle_identification_number}</IndexTable.Cell>
				<IndexTable.Cell>{vehicle_machine_number}</IndexTable.Cell>
				<IndexTable.Cell>{branch}</IndexTable.Cell>
				<IndexTable.Cell>{vehicle_status}</IndexTable.Cell>
				<IndexTable.Cell>{ statusBadge(status) }</IndexTable.Cell>
				<IndexTable.Cell>{base_cost}</IndexTable.Cell>
				<IndexTable.Cell>{bought_date}</IndexTable.Cell>
				<IndexTable.Cell>{fixing_fee}</IndexTable.Cell>
				<IndexTable.Cell>{final_price}</IndexTable.Cell>
				<IndexTable.Cell></IndexTable.Cell>
				<IndexTable.Cell></IndexTable.Cell>
				<IndexTable.Cell>
					<Text as="div" alignment="end" numeric>
						<Button variant="tertiary">Edit</Button>
						<Button variant="plain" tone="critical">Delete</Button>
					</Text>
				</IndexTable.Cell>
			</IndexTable.Row>
		),
	);

	return (
		<Page fullWidth title="Tất cả phương tiện" primaryAction={{ content: 'Thêm mới', url: '/phuong-tien/them-moi' }}>
			<LegacyCard>
				<IndexTable
					selectable={false}
					resourceName={resourceName}
					itemCount={vehicles.length}
					selectedItemsCount={0}
					headings={[
						{ title: 'Mã' },
						{ title: 'Hình ảnh' },
						{ title: 'Biển số' },
						{ title: 'Loại xe' },
						{ title: 'Năm sản xuất' },
						{ title: 'Nhãn hiệu' },
						{ title: 'Màu sắc' },
						{ title: 'Số khung' },
						{ title: 'Số máy' },
						{ title: 'Địa điểm để xe' },
						{ title: 'Tình trạng xe' },
						{ title: 'Trạng thái' },
						{ title: 'Giá nhập' },
						{ title: 'Ngày nhập' },
						{ title: 'Phí sửa chữa' },
						{ title: 'Giá nhập sau sửa chữa' },
						{ title: 'Giá bán' },
						{ title: 'Ngày bán' },
						{ title: 'Thao tác', alignment: 'end' },
					]}
				>
					{rowMarkup}
				</IndexTable>
			</LegacyCard>
		</Page>
	);
}

export default AllVehicles;