import {useState, useCallback} from 'react';
import {SearchMinor, DeleteMinor} from '@shopify/polaris-icons';
import { Page, InlineGrid, LegacyStack, LegacyCard, BlockStack, FormLayout, Text, TextField, Select, ButtonGroup, Button, ChoiceList, Combobox, Listbox, Icon, Divider } from '@shopify/polaris';

function AddNewExpense() {
	const [selected, setSelected] = useState(['phieu-thu']);
  	const handleChange = useCallback((value) => setSelected(value), []);

	const [objectSelected, setObjectSelected] = useState('phuong-tien');
  	const handleObjectChange = useCallback((value) => setObjectSelected(value), []);

	const [viaSelected, setViaSelected] = useState(['tien-mat']);
  	const handleViaChange = useCallback((value) => setViaSelected(value), []);

	let relatedObject;
	if ( 'phuong-tien' == objectSelected ) {
		relatedObject = (
			<Combobox
				activator={
				<Combobox.TextField
					prefix={<Icon source={SearchMinor} />}
					label="Phương tiện"
					labelHidden
					placeholder="Chọn phương tiện"
					autoComplete="off"
				/>
				}
			>
				<Listbox>
					<Listbox.Option value="" accessibilityLabel="29B-22263">29B-22263</Listbox.Option>
					<Listbox.Option value="" accessibilityLabel="30A-22263">30A-22263</Listbox.Option>
				</Listbox>
			</Combobox>
		);
	} else if ( 'hop-dong' == objectSelected ) {
		relatedObject = (
			<Combobox
				activator={
				<Combobox.TextField
					prefix={<Icon source={SearchMinor} />}
					label="Hợp đồng"
					labelHidden
					placeholder="Chọn hợp đồng"
					autoComplete="off"
				/>
				}
			>
				<Listbox>
					<Listbox.Option value="" accessibilityLabel="1001">HĐ số 1001</Listbox.Option>
					<Listbox.Option value="" accessibilityLabel="1002">HĐ số 1002</Listbox.Option>
					<Listbox.Option value="" accessibilityLabel="1003">HĐ số 1003</Listbox.Option>
				</Listbox>
			</Combobox>
		);
	} else if ( 'nhan-su' == objectSelected ) {
		relatedObject = (
			<Combobox
				activator={
				<Combobox.TextField
					prefix={<Icon source={SearchMinor} />}
					label="Nhân sự"
					labelHidden
					placeholder="Chọn nhân sự"
					autoComplete="off"
				/>
				}
			>
				<Listbox>
					<Listbox.Option value="" accessibilityLabel="Hoàng Văn An">Hoàng Văn An</Listbox.Option>
					<Listbox.Option value="" accessibilityLabel="Lê Quang Đạo">Lê Quang Đạo</Listbox.Option>
					<Listbox.Option value="" accessibilityLabel="Dương Văn Nam">Dương Văn Nam</Listbox.Option>
				</Listbox>
			</Combobox>
		);
	} else {
		relatedObject = <TextField
			value=""
			onChange=""
			autoComplete="off"
			placeholder="Tên đối tượng"
		/>
	}

	return (
		<Page title="Thêm mới phiếu thu chi">
			<InlineGrid columns={{ xs: 1, md: "2fr 1fr" }} gap="400">
				<BlockStack gap="400">
					<LegacyCard title="Thông tin phiếu thu chi" sectioned>
						<FormLayout>
							<ChoiceList
								title="Loại phiếu"
								choices={[
									{label: 'Phiếu thu', value: 'phieu-thu'},
									{label: 'Phiếu chi', value: 'phieu-chi'},
								]}
								selected={selected}
								onChange={handleChange}
							/>

							<TextField
								label="Tên phiếu"
								value=""
								onChange=""
								autoComplete="off"
							/>

							<Select
								label="Đối tượng liên quan"
								options={ [
									{label: 'Phương tiện', value: 'phuong-tien'},
									{label: 'Hợp đồng', value: 'hop-dong'},
									{label: 'Nhân sự', value: 'nhan-su'},
									{label: 'Khác', value: 'khac'},
								] }
								value={objectSelected}
								onChange={handleObjectChange}
							/>
							{ relatedObject }

							<TextField
								label="Mô tả"
								value=""
								multiline={4}
								autoComplete="off"
							/>

							<TextField
								label="Ghi chú"
								value=""
								multiline={4}
								autoComplete="off"
							/>
						</FormLayout>
					</LegacyCard>

					<LegacyCard title="Chi tiết phiếu thu" sectioned>
						<FormLayout>
							<LegacyStack wrap={false} alignment="leading" spacing="loose">
								<LegacyStack.Item fill>
									<FormLayout>
										<FormLayout.Group condensed>
											<TextField
												label="Tên thành phần"
												autoComplete="off"
											/>
											<Select
												label="Đơn vị tính"
												options={['kg', 'cái', 'khác']}
											/>
											<TextField
												label="Số lượng"
												type="number"
												autoComplete="off"
											/>
											<TextField
												label="Đơn giá"
												type="number"
												autoComplete="off"
											/>
											{/* <TextField
												label="Ghi chú"
												autoComplete="off"
											/> */}
											{/* <TextField
												label="Thành tiền"
												type="number"
												disabled
												autoComplete="off"
											/> */}
										</FormLayout.Group>
									</FormLayout>
									
								</LegacyStack.Item>
								<div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
							</LegacyStack>
							<LegacyStack wrap={false} alignment="leading" spacing="loose">
								<LegacyStack.Item fill>
									<FormLayout>
										<FormLayout.Group condensed>
											<TextField
												labelHidden
												label="Tên thành phần"
												autoComplete="off"
											/>
											<Select
												labelHidden
												label="Đơn vị tính"
												options={['kg', 'cái', 'khác']}
											/>
											<TextField
												labelHidden
												label="Số lượng"
												type="number"
												autoComplete="off"
											/>
											<TextField
												labelHidden
												label="Đơn giá"
												type="number"
												autoComplete="off"
											/>
											{/* <TextField
												labelHidden
												label="Ghi chú"
												autoComplete="off"
											/>
											<TextField
												labelHidden
												type="number"
												disabled
												autoComplete="off"
											/> */}
										</FormLayout.Group>
									</FormLayout>
								</LegacyStack.Item>
								<Button icon={DeleteMinor} accessibilityLabel="Remove item" />
							</LegacyStack>

							<Button>Thêm mới</Button>

							
							
						</FormLayout>
					</LegacyCard>
				</BlockStack>

				<BlockStack gap={{ xs: "400", md: "200" }}>
					<LegacyCard title="Tổng tiền" sectioned>
						<div style={ { fontSize: '20px', color: '#000', fontWeight: 'bold', marginBottom: '20px' } }>560.000 VND</div>

						<ChoiceList
							title="Hình thức giao dịch"
							choices={[
								{label: 'Tiền mặt', value: 'tien-mat'},
								{label: 'Chuyển khoản', value: 'chuyen-khoan'},
							]}
							selected={viaSelected}
							onChange={handleViaChange}
						/>
						{ 'chuyen-khoan' == viaSelected && (
							<Combobox
								activator={
								<Combobox.TextField
									prefix={<Icon source={SearchMinor} />}
									label="Số tài khoản"
									labelHidden
									placeholder="Chọn số tài khoản"
									autoComplete="off"
								/>
								}
							>
								<Listbox>
									<Listbox.Option value="" accessibilityLabel="1001">Techcombank - 190249372277002</Listbox.Option>
									<Listbox.Option value="" accessibilityLabel="1002">Techcombank - 190249372277003</Listbox.Option>
									<Listbox.Option value="" accessibilityLabel="1003">Techcombank - 190249372277004</Listbox.Option>
								</Listbox>
							</Combobox>
						)}
					</LegacyCard>
				</BlockStack>
			</InlineGrid>

			<div style={{margin: '20px 0 40px 0'}}>
				<ButtonGroup>
					<Button>Hủy</Button>
					<Button variant="primary">Thêm mới</Button>
				</ButtonGroup>
			</div>
	
		</Page>
	);
}
export default AddNewExpense;