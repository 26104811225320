import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Frame, ContextualSaveBar, Navigation, TopBar, Icon, Text } from "@shopify/polaris";
import { CirclePlusMinor } from "@shopify/polaris-icons";
import AppRoutes from "./AppRoutes";
import { useState, useCallback } from 'react';
import AppLogo from "../assets/images/logo-light.png";


import {
	HomeMinor,
	OrdersMinor,
	BillingStatementDollarFilledMajor,
	ProductsMinor,
	CustomersMajor,
	InventoryMajor
} from '@shopify/polaris-icons';

function AppNav() {
	const location = useLocation();
	return (
		<Navigation location={location.pathname}>
			<Navigation.Section
				items={[
					{
						url: "/",
						label: "Tổng quan",
						exactMatch: true,
						icon: HomeMinor,
					},
					{
						url: "/co-so",
						label: "Cơ sở",
						icon: InventoryMajor,
						subNavigationItems: [
							{
								url: '/co-so',
								label: 'Tất cả cơ sở',
							},
							{
								url: '/co-so/them-moi',
								label: 'Thêm mới',
							},
						],
					},
					{
						url: "/phuong-tien",
						label: "Phương tiện",
						icon: ProductsMinor,
						subNavigationItems: [
							{
								url: '/phuong-tien',
								label: 'Tất cả phương tiện',
							},
							{
								url: '/phuong-tien/them-moi',
								label: 'Thêm mới',
							},
							{
								url: '/phuong-tien/sua-chua-bao-duong',
								label: 'Sửa chữa/bảo dưỡng',
							},
						],
					},
					{
						url: "/hop-dong",
						label: "Hợp đồng",
						icon: OrdersMinor,
						subNavigationItems: [
							{
								url: '/hop-dong',
								label: 'Tất cả hợp đồng',
							},
							{
								url: '/hop-dong/them-moi',
								label: 'Thêm mới',
							},
						],
					},
					{
						url: "/thu-chi",
						label: "Thu chi",
						icon: BillingStatementDollarFilledMajor,
						subNavigationItems: [
							{
								url: '/thu-chi',
								label: 'Tất cả thu chi',
							},
							{
								url: '/thu-chi/them-moi',
								label: 'Thêm mới',
							},
							{
								url: '/thu-chi/tai-khoan-thanh-toan',
								label: 'Tài khoản thanh toán',
							},
						],
					},
					{
						url: "/nhan-vien",
						label: "Nhân viên",
						icon: CustomersMajor,
						subNavigationItems: [
							{
								url: '/nhan-vien',
								label: 'Tất cả nhân viên',
							},
							{
								url: '/nhan-vien/them-moi',
								label: 'Thêm mới',
							},
						],
					},
				]}
			/>
		</Navigation>
	);
}

function AppFrame() {
	const [userMenuActive, setUserMenuActive] = useState(false);
	const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
	const toggleUserMenuActive = useCallback(
		() => setUserMenuActive((userMenuActive) => !userMenuActive),
		[],
	);
	const toggleMobileNavigationActive = useCallback(
		() =>
			setMobileNavigationActive(
				(mobileNavigationActive) => !mobileNavigationActive,
			),
		[],
	);

	const userMenuActions = [
		{
			items: [{ content: 'My Profile' }, { content: 'Logout' }],
		},
	];
	const userMenuMarkup = (
		<TopBar.UserMenu
			actions={userMenuActions}
			name="Lan Anh"
			detail={"Nguyễn"}
			initials="A"
			open={userMenuActive}
			onToggle={toggleUserMenuActive}
		/>
	);

	const [searchValue, setSearchValue] = useState('');
	const searchFieldMarkup = (
		<TopBar.SearchField
			onChange={setSearchValue}
			value={searchValue}
			placeholder="Tìm kiếm nhanh: phương tiện, hợp đồng..."
			showFocusBorder
		/>
	);

	const navigate = useNavigate();
	const secondaryMenuMarkup = (
		<TopBar.Menu
			activatorContent={
				<span>
					<Icon source={CirclePlusMinor} />
					<Text as="span" visuallyHidden>
						Thêm hợp đồng
					</Text>
				</span>
			}
			onOpen={() => navigate('/hop-dong/them-moi')}
		/>
	);

	const topBarMarkup = (
		<TopBar
			showNavigationToggle
			userMenu={userMenuMarkup}
			searchField={searchFieldMarkup}
			onNavigationToggle={toggleMobileNavigationActive}
			secondaryMenu={secondaryMenuMarkup}
		/>
	);

	const logo = {
		width: 140,
		topBarSource: AppLogo,
		contextualSaveBarSource: AppLogo,
		accessibilityLabel: 'Happy Ride',
	};

	return (
		<Frame
			logo={logo}
			topBar={topBarMarkup}
			navigation={<AppNav />}
			showMobileNavigation={mobileNavigationActive}
			onNavigationDismiss={toggleMobileNavigationActive}
		>
			{/* <ContextualSaveBar
				message="Unsaved changes"
				saveAction={{
					onAction: () => console.log('add form submit logic'),
					loading: false,
					disabled: false,
				}}
				discardAction={{
					onAction: () => console.log('add clear form logic'),
				}}
			/> */}
			<AppRoutes />
		</Frame>
	);
}

export default AppFrame;