import {
	Page,
	IndexTable,
	LegacyCard,
	Text,
	Button,
	Badge
} from '@shopify/polaris';
import React from 'react';

function AllExpenses() {
	const branches = [
		{
			id: '1',
			branch: 'Nguyễn Hoàng',
			created_at: '30-11-2023 11:30',
			created_by: 'Hà',
			type: 'Phiếu Thu',
			amount: '500000',
			reason: 'Khách cọc tiền thuê xe',
			note: 'Thu từ hợp đồng số #001',
		},
		{
			id: '2',
			branch: 'Nguyễn Hoàng',
			created_at: '30-11-2023 17:30',
			created_by: 'Hà',
			type: 'Phiếu Chi',
			amount: '100000',
			reason: 'Trả cọc khách thuê xe',
			note: 'Hợp đồng thuê số #001',
		},
	];
	const resourceName = {
		singular: 'Cơ Sở',
		plural: 'Cơ Sở',
	};

	const rowMarkup = branches.map(
		(
			{ id, branch, created_at, created_by, type, amount, reason, note },
			index,
		) => (
			<IndexTable.Row
				id={id}
				key={id}
				position={index}
			>
				<IndexTable.Cell>
					<Text variant="bodyMd" fontWeight="bold" as="span">#{id}</Text>
				</IndexTable.Cell>
				<IndexTable.Cell>{branch}</IndexTable.Cell>
				<IndexTable.Cell>{created_at}</IndexTable.Cell>
				<IndexTable.Cell>{created_by}</IndexTable.Cell>
				<IndexTable.Cell><Badge progress="complete" tone={ 'Phiếu Thu' == type ? 'success' : 'warning' }>{ type }</Badge></IndexTable.Cell>
				<IndexTable.Cell>{amount}</IndexTable.Cell>
				<IndexTable.Cell>{reason}</IndexTable.Cell>
				<IndexTable.Cell>{note}</IndexTable.Cell>
				<IndexTable.Cell>
					<Text as="div" alignment="end" numeric>
						<Button variant="tertiary">Edit</Button>
						<Button variant="plain" tone="critical">Delete</Button>
					</Text>
				</IndexTable.Cell>
			</IndexTable.Row>
		),
	);

	return (
		<Page fullWidth title="Tất cả phiếu thu chi" primaryAction={{ content: 'Thêm mới', url: '/thu-chi/them-moi' }}>
			<LegacyCard>
				<IndexTable
					selectable={false}
					resourceName={resourceName}
					itemCount={branches.length}
					selectedItemsCount={0}
					headings={[
						{ title: 'STT' },
						{ title: 'Tên cơ sở' },
						{ title: 'Ngày tạo' },
						{ title: 'Tạo bởi' },
						{ title: 'Loại' },
						{ title: 'Số tiền' },
						{ title: 'Mô tả' },
						{ title: 'Ghi chú' },
						{ title: 'Thao tác', alignment: 'end' },
					]}
				>
					{rowMarkup}
				</IndexTable>
			</LegacyCard>
		</Page>
	);
}

export default AllExpenses;