import {useState, useCallback} from 'react';
import { Page, LegacyCard, Card, BlockStack, InlineGrid, Box, ButtonGroup, Button, TextField, ChoiceList, Select, Autocomplete, Icon, DropZone } from '@shopify/polaris';
import {CirclePlusMinor, SearchMinor, DeleteMinor} from '@shopify/polaris-icons';
import SingleDatePicker from '../../components/SingleDatePicker';
import DateTimePicker from '../../components/DateTimePicker';

function AddNewContract() {
	const [depositIdentitySelected, setDepositIdentitySelected] = useState(['Căn cước công dân']);
  	const handleDepositIdentityChange = useCallback((value) => setDepositIdentitySelected(value), []);

	const [depositMethodSelected, setDepositMethodSelected] = useState(['honda']);
  	const handleDepositMethodChange = useCallback((value) => setDepositMethodSelected(value), []);

	const [vehicleSelectedOptions, setVehicleSelectedOptions] = useState([]);
	const onVehicleSelected = useCallback((value) => setVehicleSelectedOptions(value), []);

	return (
		<Page
			title="Thêm mới hợp đồng"
		>
			<InlineGrid columns={{ xs: 1, md: "2fr 1fr" }} gap="400">
				<BlockStack gap="400">
					<LegacyCard title="Thông tin chung" sectioned>
						<BlockStack gap="400">
							<Autocomplete
								options={[
									{value: '29A-19155', label: '29A-19155'},
									{value: '30A-90014', label: '30A-90014'},
									{value: '20A-91053', label: '20A-91053'},
									{value: '20B1-23532', label: '20B1-23532'},
								]}
								selected={ vehicleSelectedOptions }
								onSelect={ onVehicleSelected }
								loading={false}
								textField={ <Autocomplete.TextField
									label="Phương tiện"
									value=""
									prefix={<Icon source={SearchMinor} />}
									placeholder="Tìm kiếm phương tiện"
									autoComplete="off"
								/> }
							/>
							<TextField
								label="Tiền đặt cọc"
								type="number"
								value=""
								onChange=""
								autoComplete="off"
							/>
							<Select
								label="Hình thức thu cọc"
								options={ [
									{label: 'Tiền mặt', value: 'Tiền mặt'},
									{label: 'Chuyển khoản ngân hàng', value: 'Chuyển khoản ngân hàng'},
								] }
								value={depositMethodSelected}
								onChange={handleDepositMethodChange}
							/>

							{ 'Chuyển khoản ngân hàng' == depositMethodSelected && <Select
								label="Tài khoản nhận tiền"
								options={ [
									{label: 'ACB - Hoàng Văn A - 124135135', value: 'ACB - Hoàng Văn A - 124135135'},
									{label: 'Techcombank - Ly Văn Huy - 92359239529', value: 'Techcombank - Ly Văn Huy - 92359239529'},
								] }
								value=""
							/> }

							<DateTimePicker dateLabel="Ngày thuê" timeLabel="Giờ"/>
							<DateTimePicker initialValue="" dateLabel="Ngày trả(dự kiến)" timeLabel="Giờ"/>

							<TextField
								label="Giá thuê"
								type="number"
								value=""
								onChange=""
								autoComplete="off"
								connectedRight={
									<Select
										value={'giờ'}
										label="Đơn vị"
										labelHidden
										options={['giờ', 'ngày', 'tháng']}
									/>
								}
							/>
							<TextField
								label="Phạt quá giờ"
								type="number"
								value=""
								onChange=""
								autoComplete="off"
								connectedRight={
									<Select
										value={'giờ'}
										label="Đơn vị"
										labelHidden
										options={['giờ', 'ngày', 'tháng']}
									/>
								}
							/>

							<TextField
								label="Ghi chú"
								value=""
								multiline={4}
								autoComplete="off"
							/>
						</BlockStack>
					</LegacyCard>

					<LegacyCard title="Thông tin khách hàng" sectioned>
						<BlockStack gap="400">
							<TextField
								label="Họ và tên"
								value=""
								onChange=""
								autoComplete="off"
							/>
							<TextField
								label="Số điện thoại"
								value=""
								onChange=""
								autoComplete="off"
							/>
							<ChoiceList
								title="Giấy tờ đặt cọc"
								choices={[
									{label: 'Căn cước công dân', value: 'Căn cước công dân'},
									{label: 'Bằng lái xe', value: 'Bằng lái xe'},
									{label: 'Khác', value: 'khac'},
								]}
								selected={depositIdentitySelected}
								onChange={handleDepositIdentityChange}
							/>
							{ 'khac' == depositIdentitySelected && <TextField labelHidden placeholder="Nhập tên giấy tờ đặt cọc" autoComplete="off" />}
							<DropZone label="Hình ảnh giấy tờ">
								<DropZone.FileUpload />
							</DropZone>
						</BlockStack>
					</LegacyCard>

				</BlockStack>
				<BlockStack gap={{ xs: "400", md: "200" }}>
					<LegacyCard title="" sectioned>
						<BlockStack gap="400">
							<Select
								label="Trạng thái"
								options={ [
									{label: 'Đang thuê xe', value: 'Đang thuê xe'},
									{label: 'Đã trả xe', value: 'Đã trả xe'},
								] }
								value={"Đang thuê xe"}
							/>

							<Select
								label="Cơ sở"
								options={ [
									{label: 'Thuốc Bắc', value: 'Thuốc Bắc'},
									{label: 'Nguyễn Hoàng', value: 'Nguyễn Hoàng'},
									{label: 'Hồ Tùng Mậu', value: 'Hồ Tùng Mậu'},
								] }
								value={"Thuốc Bắc"}
							/>

						</BlockStack>
					</LegacyCard>
					<LegacyCard title="" sectioned>
						<BlockStack gap="400">
							<DropZone label="Hình ảnh xe trước khi thuê">
								<DropZone.FileUpload />
							</DropZone>
						</BlockStack>
					</LegacyCard>
				</BlockStack>
			</InlineGrid>

			<div style={{margin: '20px 0 40px 0'}}>
				<ButtonGroup>
					<Button>Hủy</Button>
					<Button variant="primary">Thêm mới</Button>
				</ButtonGroup>
			</div>
		</Page>
	)
}
export default AddNewContract;
